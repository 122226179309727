html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: 'Google-Sans'; */
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

body * {
  box-sizing: border-box;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.cursor-pt {
  cursor: pointer;
}

input::placeholder {
  font-size: 100%;
}

*:focus {
  outline: unset;
}

#root {
  min-height: 100vh;
}

html {
  /* overflow-y: scroll; */
}
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.cursor-pt {
  cursor: pointer;
}

input::placeholder {
  font-size: 100%;
}

*:focus {
  outline: unset;
}

#root {
  min-height: 100vh;
}

.text-gold {
  color: #ffb954;
}

.text-green {
  color: #74e41c;
}

.text-pink {
  color: #f25aa0;
}

.text-red {
  color: #ff4e17;
}

.text-orange {
  color: #ffb954;
}

.text-blue {
  color: #5dbeff;
}

.text-gradient-blue {
  display: inline-block;
  background: -webkit-linear-gradient(90deg, #5146ce 0%, #4ba8ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-green {
  display: inline-block;
  background: linear-gradient(90deg, #039d00 0%, #74e41c 100.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-pink {
  display: inline-block;
  background: linear-gradient(90deg, #b22edf 0%, #ee6d30 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-orange {
  display: inline-block;
  background: linear-gradient(90deg, #ff8c2f 0%, #ffba35 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-blue {
  background: -webkit-linear-gradient(90deg, #5146ce 0%, #4ba8ff 100%);
}

.bg-gradient-green {
  background: linear-gradient(90deg, #039d00 0%, #74e41c 100.07%);
}

.bg-gradient-pink {
  background: linear-gradient(90deg, #b22edf 0%, #ee6d30 100%);
}

.bg-gradient-orange {
  background: linear-gradient(90deg, #ff8c2f 0%, #ffba35 100%);
}

.size-1 {
  /* font-family: 'Genos', sans-serif; */
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.size-2 {
  /* font-family: 'Genos', sans-serif; */
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
}

.size-3 {
  /* font-family: "gaposis"; */
  font-size: 32px;
  line-height: 34px;
  letter-spacing: 0.01em;
}

.size-4 {
  /* font-family: "gaposis", sans-serif; */
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0.01em;
}

.size-5 {
  /* font-family: "Genos", sans-serif; */
  font-size: 80px;
  line-height: 87px;
  font-weight: 600;
}

.size-6 {
  /* font-family: "Genos", sans-serif; */
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}

.size-7 {
  /* font-family: "Genos", sans-serif; */
  font-size: 80px;
  line-height: 87px;
  font-weight: 600;
}
a,
p,
span,
label {
  font-size: 0.875em;
}
#root {
  position: relative;
  overflow: hidden;
}

.color-white {
  color: #ffff;
}

.color-blue {
  color: #4ba8ff;
}

.gradient-blue {
  background: linear-gradient(90deg, #039d00 0%, #74e41c 100.07%);
}

.color-yellow {
  color: #ffb935;
}

.color-black {
  color: #170724;
}

.color-pink {
  color: #f25aa0;
}

.color-green {
  color: #74e41c;
}

.color-purple {
  color: #8972d7;
}

.color-violet {
  color: #351a6f;
}

.color-red {
  color: #ff4e17;
}

.color-dark {
  color: #241e2e;
}

input {
  border: none;
  outline: none;
}

input:hover,
input:active,
input:focus {
  border: none;
  outline: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 1400px) {
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .size-5 {
    font-size: 70px;
    line-height: 80px;
  }
}

@media screen and (max-width: 1199px) {
  .size-menu {
    /* font-family: "gaposis"; */
    font-size: 50px;
    line-height: 54px;
    font-weight: 400;
  }

  .size-1 {
    /* font-family: 'Genos', sans-serif; */
    font-size: 18px;
    line-height: 20px;
  }

  .size-2 {
    /* font-family: 'Genos', sans-serif; */
    font-size: 20px;
    line-height: 22px;
  }

  .size-3 {
    /* font-family: "gaposis"; */
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.01em;
  }

  .size-4 {
    /* font-family: "gaposis"; */
    font-size: 36px;
    line-height: 38px;
    letter-spacing: 0.01em;
  }

  .size-5 {
    /* font-family: "Genos", sans-serif; */
    font-size: 60px;
    line-height: 72px;
    font-weight: 600;
  }

  .size-6 {
    /* font-family: "Genos", sans-serif; */
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .size-7 {
    /* font-family: "Genos", sans-serif; */
    font-size: 60px;
    line-height: 72px;
    font-weight: 600;
  }
}

@media screen and (max-width: 767px) {
  .size-1 {
    /* font-family: 'Genos', sans-serif; */
    font-size: 14px;
    line-height: 20px;
  }

  .size-2 {
    /* font-family: 'Genos', sans-serif; */
    font-size: 16px;
    line-height: 20px;
  }

  .size-3 {
    /* font-family: "gaposis"; */
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .size-4 {
    /* font-family: "gaposis"; */
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 0.01em;
  }

  .size-5 {
    /* font-family: "Genos", sans-serif; */
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
  }

  .size-6 {
    /* font-family: "Genos", sans-serif; */
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }

  .size-7 {
    /* font-family: "Genos", sans-serif; */
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
  }
}

@media screen and (max-width: 480px) {
  /* .size-2-mobile {
      font-family: 'Genos', sans-serif;
      font-size: 14px;
      line-height: 20px;

  } */
}

@media screen and (max-width: 374px) {
  .size-2-mobile {
    /* font-family: 'Genos', sans-serif; */
    font-size: 14px;
    line-height: 20px;
  }
}
